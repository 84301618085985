import React from 'react';

import { fireGTMEvent } from 'src/features/analytics/hooks/useGtm';

import { NotFound } from '../../components/NotFound/NotFound';

import '../../scss/_root-component.scss';

export default function NotFoundRoot() {
    React.useEffect(() => {
        fireGTMEvent({ event: 'pageview', pageType: 'Not Found Page' });
    }, []);
    return <NotFound />;
}
